var resizeTimer;

jQuery(window).on('resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    // jQuery(window).load(function() {
		// jQuery('.js-focal-point-image').responsify();
	// });
	// jQuery(window).resize(function(){
	//  jQuery('.js-focal-point-image').css('width', '').css('height', '').css('left', '').css('top', '').responsify();
    // });
    // console.log('resize');

  }, 2500);

});