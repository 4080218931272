jQuery(document).foundation();

jQuery(document).on("show.zf.dropdownmenu", function (ev, $el) {
    jQuery('body').addClass('menu-open');
 });
jQuery(document).on("hide.zf.dropdownmenu", function (ev, $el) {
    jQuery('body').removeClass('menu-open');
 });


 jQuery('figure.cap-bot').on('mousedown', function () {
     jQuery(this).addClass('figure-box-active');
  });

  jQuery('figure.cap-bot').on('mouseleave', function () {
    jQuery(this).removeClass('figure-box-active');
 });